<script setup>
const title = '天蓝云扬'
// const name = 'MainFrame'
// import {ArrowRight} from '@element-plus/icons'
// import { ref } from 'vue'
// const fill=ref(true)
</script>

<template>
  <div id="mainframe">
    <el-container class="frame">
      <el-header class="frame-header">
        <div id="frame-header-titlebar">
          <div id="frame-header-icon"><img src="img/logo.png" alt="logo"/></div>
          <div id="frame-header-title" v-text="title"></div>
        </div>
<!--        <el-row :gutter="0" :justify="start">-->
<!--          header-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-breadcrumb :separator-icon="ArrowRight">-->
<!--            <el-breadcrumb-item :to="{ path: '/' }">homepage</el-breadcrumb-item>-->
<!--          </el-breadcrumb>-->
<!--        </el-row>-->
      </el-header>
      <el-main class="frame-main">main</el-main>
      <el-footer class="frame-footer">
        <div id="frame-footer-reference">
          <span><img src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png"/>川公网安备 <a href="https://beian.mps.gov.cn/#/query/webSearch?code=51078202110234" target="_blank">51078202110234号</a></span>
          &nbsp;
          <span>蜀ICP备
            <el-tooltip
              class="box-item"
              effect="light"
              content="跳转并复制"
              placement="top"
            >
              <a href="https://beian.miit.gov.cn" target="_blank" style="text-decoration:none">2024067530号-1</a>
            </el-tooltip></span>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<style scoped lang="scss">
$HeaderHeight: 150px;
$FooterHeight: 150px;

#mainframe {
  width: 95vmin;
  margin: 10px auto;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 0 20px;
}

#mainframe>.frame {
  width: 100%;
}

#mainframe>.frame>.frame-header,
#mainframe>.frame>.frame-footer{
  background-color: #ddd;
  position: relative;
  width: 100%;
}

#mainframe>.frame>.frame-header{
  min-height: $HeaderHeight;
  user-select: none;
}

#mainframe>.frame>.frame-header>#frame-header-titlebar{
  position: absolute;
  left: 20px;
  top: 20px;
  height: 50%;
  width: 100%;
}

#mainframe>.frame>.frame-header>#frame-header-titlebar>#frame-header-icon{
  position: absolute;
  left: 0;
  width: 50px;
  height: 40px;
  display: inline-block;
}

#mainframe>.frame>.frame-header>#frame-header-titlebar>#frame-header-icon>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#mainframe>.frame>.frame-header>#frame-header-titlebar>#frame-header-title{
  position: absolute;
  left: 55px;
  line-height: 40px;
  text-align: left;
  display: inline;
  font-family: 'Microsoft YaHei','黑体',ui-sans-serif!important;
  font-size: 35px;
  vertical-align: middle;
}

#mainframe>.frame>.frame-main{
  min-height: calc( 100vh - 150px - 150px - 10px - 10px);
  background-color: #fff;
}

#mainframe>.frame>.frame-footer{
  min-height: $HeaderHeight;
}

#mainframe>.frame>.frame-footer>#frame-footer-reference{
  position: absolute;
  height: 2em;
  line-height: 2em;
  left: 0;
  top: calc( 100% - 2em - 2em );
  width: 100%;
  text-align: center;
  color: gray;
  font-size: xx-small;
}

#mainframe>.frame>.frame-footer>#frame-footer-reference{
  &>span>img{
    width: 2em;
    height: 2em;
    vertical-align: middle;
    margin-top: -0.5em;
    margin-right: 0.2em;
  }
  & a{
    text-decoration:none;
    color: inherit;
    &:hover{
      color: #00b0f0;
    }
    &:active{
      color: blue;
    }
  }
}

</style>
